<template>
  <div class="app">
    <navbar v-if="$router.currentRoute.name !== 'PrivacyPolicy'" />
    <router-view />
    <!-- <social v-if="$router.currentRoute.name !== 'Download'"/> -->
  </div>
</template>

<script>
const Navbar = () => import("./components/Navbar.vue");
// const Social = () => import("./components/Social.vue");

export default {
  name: "App",
  components: {
    navbar: Navbar,
    // Social
  }
};
</script>

<style>
.app {
  position: relative;
  min-height: 100vh;
}
</style>
