import Vue from "vue";
import VueRouter from "vue-router";

const Home = () =>
    import("./components/Home.vue");
const Driver = () =>
    import("./components/Driver.vue");
const PrivacyPolicy = () =>
    import("./components/PrivacyPolicy.vue");
// const Download = () =>
//     import ("./components/Download.vue");
const business = () =>
    import("./components/business.vue");
const learnMore = () =>
    import("./components/learnMore.vue");
const DataPrivacy = () => import("./components/DataPrivacy.vue");

Vue.use(VueRouter);

const routes = [ {
    path: "/",
    name: "Home",
    component: Home
},
{
    path: "/driver",
    name: "Driver",
    component: Driver
},

// {
//     path: "/downloads",
//     name: "Download",
//     component: Download
// },
{
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy
},
{
    path: "/data-privacy",
    name: "DataPrivacy",
    component: DataPrivacy
},
{
    path: "/business",
    name: "business",
    component: business
},
{
    path: "/learn-more",
    name: "learn-more",
    component: learnMore
},
{
    path: "/*",
    name: "All",
    component: Home
},
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;