import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
  Loading,
  Message,
  Collapse,
  CollapseItem,
  Popover,
  Card,
  Button
} from "element-ui";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import "./assets/css/theme/index.css";
import "./assets/css/style.css";

import router from "./router";

import db from "./plugins/firebase";
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Loading.directive);

Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Card);
Vue.use(Button);
Vue.use(Popover);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
locale.use(lang);

new Vue({
  db,
  router,
  render: h => h(App)
}).$mount("#app");
